import React, { Component } from "react"
import "../assets/css/success.css"
import { Link } from 'gatsby';
import closeChat from "../images/close-icon.svg"
import booklense from "../images/booklense.svg"

class Popcover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            linkText:this.props.linkText,
            popupMessage:this.props.popupMessage,
            popupHead:this.props.popupHead,
            btnLabel:this.props.btnLabel
        };
        //console.log('--constructor--')
    }
    
    render() {
        const { popupMessage,popupHead, btnLabel, showPopup, linkText} = this.state;
        return (
            <span className="mypopup">
            <a className="btn btn-outline" onClick={() => this.setState({ showPopup: true })} dangerouslySetInnerHTML={{__html: linkText}}></a>
            
            {showPopup ?
                <div className="success-container how">
                    <div className="success-inner small">
                        <div  className="close-btn close">
                            <img alt="close" className="" src={closeChat} onClick={() => this.setState({ showPopup: false })} />
                        </div>
                        <div className="content">
                            <h2 className="head">{popupHead}</h2>
                            <div className="how-text alft" dangerouslySetInnerHTML={{__html: popupMessage}}></div>
                        </div>
                        
                    </div>
                </div>
                :''}
            </span>
        );
    }
}



export default Popcover;
