import '../assets/css//covered.css';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { Link } from "gatsby"
import Footer from '../components/footer';
import Layout from '../components/layout';
import NewsLatter from '../components/newsletter';
import SEO from '../components/seo';
import ShoppingContainer from '../components/shoppingcontainer';
import chatbottom from '../images/chat-bottom-icon.svg';
import chatProfile from '../images/chat-profile.png';
import closeChat from '../images/close-icon.svg';
import planimg13 from '../images/covered/accumulator.png';
import arrowDown from '../images/covered/arrow-down.svg';
import planimg8 from '../images/covered/brake-disc.png';
import planimg11 from '../images/covered/car-light.png';
import planimg4 from '../images/covered/car.png';
import planimg14 from '../images/covered/check.png';
import planimg3 from '../images/covered/Drivetrain.png';
import planimg1 from '../images/covered/Engine.jpg';
import planimg5 from '../images/covered/engine.png';
import planimg15 from '../images/covered/forbidden-mark.png';
import planimg10 from '../images/covered/ice-crystal.png';
import planimg9 from '../images/covered/power.png';
import planimg12 from '../images/covered/speedometer.png';
import planimg6 from '../images/covered/steering-wheel.png';
import planimg7 from '../images/covered/suspension.png';
import planimg2 from '../images/covered/Transmission.png';
import handIcon from '../images/hand.svg';
import booklense from "../images/booklense.svg"
import lineImage from '../images/line2.png';
import FactoryPopup from "../components/popupFactory";
import { planData } from '../components/CoveragePlan';
import Popcover from "../components/popcover";
const iconObj = { planimg1, planimg2, planimg3, planimg4, planimg5, planimg6, planimg7, planimg8, planimg9, planimg10, planimg11, planimg12, planimg13 }

const CoverdPage = (props) => {
const [coveragePlan, setCoveragePlan] = useState('Engine');

const CollapseHeader = (props) => (
    <div onClick={() => closeOtherTabs(props)} className={`collapse-header selected${props.id}`}>
        <img src={props.icon} alt="motor" />
        <h4 className="title1">{props.header}</h4>
    </div>
);

const scrollToTab = () => {
    document.getElementById("coveragePlanView").scrollIntoView({ behavior: 'smooth' });
}

const closeOtherTabs = (props) => {
    setTimeout(() => {
        var y = document.getElementsByClassName("Collapsible__trigger is-open");
        for (let i = 0; i < y.length; i++) {
            if (!y[i].childNodes[0].className.includes(`selected${props.id}`)) {
                y[i].click();
            }
        }
        let elementScroll = document.getElementsByClassName(`selected${props.id}`);
        elementScroll[0].scrollIntoView({ block: 'center', behavior: 'smooth'});
        
    }, 500);
    setCoveragePlan(props.header);

}

return (
<Layout rest={props} layoutType="main">
<SEO title="Servicecontract.com - What's Covered" keywords={[`What's Covered`, `Extented warranty`]} />

{/* POPUP START */}
        
        {/* POPUP END */} 
<div className="chat-content" style={{ display: 'none' }}>
<div className="chat-content-circle">
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
<g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
<g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
<path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
<circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
<circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
<circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
</g>
</g>
</svg>
</div>
<div className="chat-box">

<div className="chatbox-inner">
<div className="chat-profile">
<img className="" src={chatProfile} alt="" />
</div>
<span className="close-chat"><img className="" src={closeChat} alt="" /></span>
<p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
</div>
<img className="chat-bottom" src={chatbottom} alt="" />
</div>

</div>

<section className="process-work-container">
<div id="coveragePlanView" className="container">
    <br /><br /><br />
<h3 className="title">Flexible, Affordable Coverage Options</h3>
<div className="btn-container">
    <Popcover
        linkText={'Vehicle Service Contract'}
        popupHead={'Vehicle Service Contract'} 
        popupMessage={`A Vehicle Service Contract provides worry-free protection against the unexpected expense and inconvenience of breakdowns and costly repairs that may be required after your warranty expires. In addition, a VSC covers the mechanical portion of your vehicle, which auto insurance does not. <br />With four levels of coverage and selectable additional options, you choose the plan that best fits your needs.`}  
        btnLabel={'Ok'} 
    />
    <Popcover
        linkText={'Lease Care Ultimate VSC'}
        popupHead={'Lease Care Ultimate VSC'} 
        popupMessage={`Specialty coverage for leased vehicles! Our high-value, low-cost Lease Care Ultimate VSC includes comprehensive coverage for the mechanical breakdown of your vehicle, additional protections for such common issues as windshield damage, flat tires, and minor dent repair, and extended protections on audio equipment, interior fabric, and more. <br />Also included: Fully pre-paid maintenance that covers up to two service visits per year — reflecting a total of $800 in value!`}  
        btnLabel={'Ok'} 
    />
    <Popcover
        linkText={'What’s Included With Your Plan'}
        popupHead={'What’s Included With Your Plan'} 
        popupMessage={`
        Unlike other offerings, our VSC subscriptions are packed with extra perks, including:
        <ul class='mini'>
        <li>Emergency roadside assistance</li>
        <li>Rental car coverage</li>
        <li>The Stacks Reward Plan</li>
        <li>Complimentary ID Theft Protection benefits for you and your family</li>
        </ul>
        Plans also come with the option to add Tire & Wheel coverage to protect one of the most critical components of your vehicle.
        `}  
        btnLabel={'Ok'} 
    />



</div>
</div>
</section>

<section className="covered-offers">
<div id="coveragePlanView" className="container">
<h3 className="title">Multiple Levels of Coverage Available</h3>
<p className="subtitle">The only service contract with a flexible, monthly subscription agreement.</p>

<div className="coverage-plan-container">
<div className="plan-header">

<div className="plan-heading">
<h3>Coverage Plan Level</h3>
</div>
<div className="plan plan-1">
<h4>Powertrain </h4>
<p>as low as</p>
<span><sup>$</sup>35<sup className="small-2">*</sup></span>
<p>per month</p>
<img className="arrow" src={arrowDown} alt="" />
</div>

<div className="plan plan-1">
<h4>Enhanced </h4>
<p>as low as</p>
<span><sup>$</sup>38<sup className="small-2">*</sup></span>
<p>per month</p>
<img className="arrow" src={arrowDown} alt="" />
</div>

<div className="plan plan-2">
<h4>Almost Comprehensive</h4>
<p>as low as</p>
<span><sup>$</sup>42<sup className="small-2">*</sup></span>
<p>per month</p>
<img className="arrow" src={arrowDown} alt="" />
</div>

<div className="plan plan-3">
<h4>Comprehensive</h4>
<p>as low as</p>
<span><sup>$</sup>50<sup className="small-2">*</sup></span>
<p>per month</p>
<img className="arrow" src={arrowDown} alt="" />
</div>

</div>

<div className="plan-body">
<div className="plan-sidebar">
{
planData.map((pData, pKey) => {
const icon = `planimg${pKey + 1}`;
return (
<div key={pKey} onClick={() => { setCoveragePlan(pData.heading); scrollToTab()}} className={`box cursor-pnt ${coveragePlan === pData.heading && 'active'}`}>
<img src={`${iconObj[icon]}`} alt="" />
<span>{pData.heading}</span>
</div>
)
})
}
</div>

<div className="main-sub-page" id="open-subpage">
<div className="sub-page">
{
planData.map((pData, pKey) => {
return pData.heading === coveragePlan &&
pData.innerData.map((data, key) => {
return (
    <div key={key}>
        <h3 className="sub-page-heading">{data.title}</h3>
        <div className={`sub-page-content ${pData.innerData.length - 1 === key && 'last'}`}>
            <ul className="altclr deskover">
                {
                    data.data.map((d, k) => {
                        return (
                            <li key={k} >
                                <p>{d.name}</p>
                                <div className="img-setting">
                                    <div><img src={d['plan1'] ? planimg14 : planimg15} alt="" /></div>
                                    <div><img src={d['plan2'] ? planimg14 : planimg15} alt="" /></div>
                                    <div><img src={d['plan3'] ? planimg14 : planimg15} alt="" /></div>
                                    <div><img src={d['plan4'] ? planimg14 : planimg15} alt="" /></div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>
)
});
})
}
<div className="covered-btn-div">
{/* <button className="btn covered-btn">GET YOUR PRICE</button> */}
<Link className="btn covered-btn" to="/vehicleInfo">Get Your Quote</Link>
</div>
</div>
</div>
</div>
<div className="collapse-container">
{
planData.map((pData, pKey) => {
const icon = `planimg${pKey + 1}`;
return (
<Collapsible key={pKey} trigger={<CollapseHeader header={pData.heading} id={pKey} icon={`${iconObj[icon]}`} />} >
{
pData.heading === coveragePlan &&
pData.innerData.map((data, key) => {
return (
    <div key={key} >
        <p className="sbtitle">{data.title}</p>
        <div className="inner-content">
            <ul className="altclr">
                {
                    data.data.map((d, k) => {
                        return (
                            <li key={k}>
                                <p>{d.name}</p>
                                <div className="img-setting">
                                    <div><img src={d['plan1'] ? planimg14 : planimg15} alt="" /></div>
                                    <div><img src={d['plan2'] ? planimg14 : planimg15} alt="" /></div>
                                    <div><img src={d['plan3'] ? planimg14 : planimg15} alt="" /></div>
                                    <div><img src={d['plan4'] ? planimg14 : planimg15} alt="" /></div>
                                </div>
                            </li>
                        )
                    })
                }

            </ul>
        </div>
        {
            key === pData.innerData.length - 1 &&
            <div className="covered-btn-div">
                {/* <button className="btn covered-btn">GET YOUR PRICE</button> */}
                <Link className="btn covered-btn" to="/vehicleInfo">Get Your Price</Link>
            </div>
        }
    </div>
)
})
}
</Collapsible>
)
})
}
</div>
</div>
<div className="covered-bottom">
{/* <div className="text">
<p>*Core, comfort and convenience components vary by coverage plan level.<br /> Check your contract for details.</p>
</div> */}
<p className="disclimer">* Actual prices vary based on your vehicle, mileage, and deductible chosen.  Please click <Link to="/vehicleInfo/">Get Your Quote</Link> to get your exact cost.</p>
            <br/>
       <FactoryPopup />
</div>
</div>
</section>
{/* <ShoppingContainer /> */}
{/* <NewsLatter /> */}

<section className="process-work-container newsec20">
    
        <div className="container">
        <h4 className="title">Best Price Guarantee</h4>
          <div class="boxcenter2">
            <img src={booklense} width="50%" />
          </div>
          <div class="boxcenter2 lintop">
          
            <h3>How does it work?</h3>
            <p class="titles">
            This is easy! If you find a better price while searching for a service contract online, 
            simply provide us the other price quote and we will beat it! No explanations, no caveats … just a solid bargain for you! Questions? Click the contact us button 
            or email us at <a href="mailto:help@servicecontract.com">help@servicecontract.com.</a>
            <br />
            <div class="howpop-btn">
            <Link to="/contact/" className="btn primary get-price">Contact Us</Link>
            </div>
            </p>
            
          </div>
          </div>
        </section>

        


<div className="break-section">
<img src={lineImage} alt="line" />
</div>
<Footer />
</Layout>
);
}
export default CoverdPage